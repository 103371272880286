// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Outfit", sans-serif;
    margin-top: 0%;
}
body{
    background-color: #F5F5F5;
}

a{
    text-decoration: none;
    color: inherit;
    line-height: 1;
    cursor: pointer;
}

.containe{


    padding-left: 2%;
    padding-right: 0%;
}

.card6 {
    width: 400px;
    height: 500px;
    border: 1px solid #ccc;
    border-radius: 8px;
    display: flex;
    /* Enables flexbox */
    justify-content: center;
    /* Centers horizontally */
    align-items: center;
    /* Centers vertically */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* background-color: black; */
}

.cont {
    display: grid;
    place-items: center;
    height: 80vh;
    margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":";AAEA;IACI,SAAS;IACT,UAAU;IACV,sBAAsB;IACtB,iCAAiC;IACjC,cAAc;AAClB;AACA;IACI,yBAAyB;AAC7B;;AAEA;IACI,qBAAqB;IACrB,cAAc;IACd,cAAc;IACd,eAAe;AACnB;;AAEA;;;IAGI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,oBAAoB;IACpB,uBAAuB;IACvB,yBAAyB;IACzB,mBAAmB;IACnB,uBAAuB;IACvB,wCAAwC;IACxC,6BAA6B;AACjC;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,SAAS;AACb","sourcesContent":["\n@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');\n*{\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n    font-family: \"Outfit\", sans-serif;\n    margin-top: 0%;\n}\nbody{\n    background-color: #F5F5F5;\n}\n\na{\n    text-decoration: none;\n    color: inherit;\n    line-height: 1;\n    cursor: pointer;\n}\n\n.containe{\n\n\n    padding-left: 2%;\n    padding-right: 0%;\n}\n\n.card6 {\n    width: 400px;\n    height: 500px;\n    border: 1px solid #ccc;\n    border-radius: 8px;\n    display: flex;\n    /* Enables flexbox */\n    justify-content: center;\n    /* Centers horizontally */\n    align-items: center;\n    /* Centers vertically */\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    /* background-color: black; */\n}\n\n.cont {\n    display: grid;\n    place-items: center;\n    height: 80vh;\n    margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
